<template>
  <div >
    <header class="fd-app-mode-header">
      <div
        class="fd-app-platform-header-bg fast-fundo-blur"
        :style="{ backgroundImage: 'url(' + fastPlataforma.banner_plataforma + ')', backgroundColor: '#000' }"
      >
        <div v-if="fastCurso.length" class="fd-app-mode-header-overlay">
          <div class="container">
            <div class="fd-app-mode-header-overlay-content">
              <div class="fd-app-mode-header-overlay-content-title">
                <h1>{{ fastCurso[0].nome_curso }}</h1>
                <p>{{ fastCurso[0].detalhe_curso }}.</p>
              </div>
              <div class="fd-app-mode-header-overlay-content-options">
                <div class="fd-app-mode-header-overlay-content-options-body">
                  <div class="fd-app-mode-header-overlay-content-options-content">
                    <div class="fd-app-mode-header-overlay-content-options-content-price-info">
                      <div
                        class="fd-app-mode-header-overlay-content-options-content-price-info-banner"
                        style="height: auto"
                      >
                        <img
                          v-if="fastCurso[0].logo_curso"
                          class="img-fluid"
                          :src="fastCurso[0].logo_curso"
                          alt
                        >
                        <img
                          v-else
                          class="img-fluid"
                          :src="require('@/assets/images/app/plataforma-img.jpg')"
                          alt
                        >
                      </div>
                      <div
                        v-if="fastCurso[0].id_item_cobranca"
                        class="fd-app-mode-header-overlay-content-options-content-price-info-footer"
                      >
                        <strong>
                          <span>R$</span>
                          {{ formataPreco(fastCurso[0].valor_item) }}
                        </strong>
                      </div>
                      <p
                        v-else
                        class="fd-app-mode-header-overlay-content-options-content-price-info-desconto text-center"
                      >
                        Valor sob demanda
                      </p>

                      <div
                        class="fd-app-mode-header-overlay-content-options-content-price-info-actions mt-4"
                      >
                        <a
                          v-if="fastCurso[0].carrinho"
                          :href="'/loja-virtual/' + $route.params.id_plataforma + '/carrinho'"
                          class="btn btn-primary btn-lg"
                        >
                          No carrinho
                          <span
                            class="badge badge-light"
                          >{{ $store.state.fastCarrinho.length }}</span>
                        </a>
                        <a
                          v-else
                          href="#"
                          class="btn btn-primary btn-lg"
                          @click.prevent="adicionaCarrinho(fastCurso[0])"
                        >
                          + Carrinho
                          <span
                            class="badge badge-light"
                          >{{ $store.state.fastCarrinho.length }}</span>
                        </a>
                      </div>

                      <hr>
                      <div
                        class="fd-app-mode-header-overlay-content-options-content-price-info-material-list"
                      >
                        <strong>Materiais inclusos</strong>
                        <ul id="ulAtividadesCurso">
                          <li
                            v-if="fastCursoAtividades.qtde_arquivo_aulas"
                          >
                            {{ fastCursoAtividades.qtde_arquivo_aulas }} Arquivos
                          </li>
                          <li
                            v-if="fastCursoAtividades.qtde_audio_aulas"
                          >
                            {{ fastCursoAtividades.qtde_audio_aulas }} Audios
                          </li>
                          <li
                            v-if="fastCursoAtividades.qtde_live_aulas"
                          >
                            {{ fastCursoAtividades.qtde_live_aulas }} Lives
                          </li>
                          <li
                            v-if="fastCursoAtividades.qtde_quiz_aulas"
                          >
                            {{ fastCursoAtividades.qtde_quiz_aulas }} Quizzes
                          </li>
                          <li
                            v-if="fastCursoAtividades.qtde_video_aulas"
                          >
                            {{ fastCursoAtividades.qtde_video_aulas }} Video Aulas
                          </li>
                        </ul>
                      </div>
                      <div
                        class="fd-app-mode-header-overlay-content-options-content-price-info-cupom"
                      />
                    </div>
                  </div>
                  <div class="fd-app-mode-header-overlay-content-options-social d-none">
                    <span>
                      <span class="mr-2">Compartilhe:</span>
                      <div
                        id="share"
                        class="jssocials"
                      >
                        <div class="jssocials-shares">
                          <div class="jssocials-share jssocials-share-facebook">
                            <a
                              target="_blank"
                              href="https://facebook.com/sharer/sharer.php?u=https%3A%2F%2Ffastead.com%2Fcursos_ondemand_detalhes.html%3FIdCurso%3D1215"
                              class="jssocials-share-link"
                            >
                              <i class="fab fa-facebook-square jssocials-share-logo" />
                            </a>
                            <div class="jssocials-share-count-box jssocials-share-no-count">
                              <span class="jssocials-share-count" />
                            </div>
                          </div>
                          <div class="jssocials-share jssocials-share-twitter">
                            <a
                              target="_blank"
                              href="https://twitter.com/share?url=https%3A%2F%2Ffastead.com%2Fcursos_ondemand_detalhes.html%3FIdCurso%3D1215&amp;text=FastEAD%20%7C%20Plataforma"
                              class="jssocials-share-link"
                            >
                              <i class="fab fa-twitter-square jssocials-share-logo" />
                            </a>
                            <div class="jssocials-share-count-box jssocials-share-no-count">
                              <span class="jssocials-share-count" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="getUrlParameter('secretaria')" class="fd-app-mode-header-overlay">
          <div class="container">
            <div class="fd-app-mode-header-overlay-content">
              <div class="fd-app-mode-header-overlay-content-title">
                <h1>{{ fastSecretariaCurso.nome_curso }}</h1>
                <p>{{ fastSecretariaCurso.detalhe_curso }}.</p>
              </div>
              <div class="fd-app-mode-header-overlay-content-options">
                <div class="fd-app-mode-header-overlay-content-options-body">
                  <div class="fd-app-mode-header-overlay-content-options-content">
                    <div class="fd-app-mode-header-overlay-content-options-content-price-info">
                      <div
                        class="fd-app-mode-header-overlay-content-options-content-price-info-banner"
                        style="height: auto"
                      >
                        <img
                          v-if="fastSecretariaCurso.logo_curso"
                          class="img-fluid"
                          :src="fastSecretariaCurso.logo_curso"
                          alt
                        >
                        <img
                          v-else
                          class="img-fluid"
                          :src="require('@/assets/images/app/plataforma-img.jpg')"
                          alt
                        >
                      </div>
                      <div
                        class="fd-app-mode-header-overlay-content-options-content-price-info-footer"
                      >
                        <strong>
                          <span>R$</span>
                          {{ formataPreco(fastSecretariaCurso.preco) }}
                        </strong>
                      </div>
                      

                      <div
                        class="fd-app-mode-header-overlay-content-options-content-price-info-actions mt-4"
                      >
                        <a
                          v-if="fastSecretariaCurso.turmas.length"
                          :href="'/loja-virtual/' + $route.params.id_plataforma + '/checkout?secretaria'"
                          @click.prevent="adicionaCarrinho(fastSecretariaCurso)"
                          class="btn btn-primary btn-lg"
                        >
                          <b-icon icon="check2-square"></b-icon> Matricular-se                         
                        </a>
                        <a v-else-if="!fastSecretariaCurso.turmasLoading && !fastSecretariaCurso.turmas.length" href="#" class="btn btn-secondary btn-lg">
                          <b-icon icon="check2-square"></b-icon> Matricular-se  
                        </a>
                      </div>

                      <hr>
                      <div
                        class="fd-app-mode-header-overlay-content-options-content-price-info-material-list"
                      >
                        <strong>Turmas disponíveis</strong>
                        <ul>
                          <li v-for="turma in fastSecretariaCurso.turmas" :key="turma.id_turma">
                            <b-icon icon="people-fill"></b-icon> {{turma.sigla_turma}}
                            <span v-if="turma.turno == 1"> - Manhã</span>
                            <span v-else-if="turma.turno == 2"> - Tarde</span>
                            <span v-else-if="turma.turno == 3"> - Noite</span>
                          </li>
                          <li v-if="!fastSecretariaCurso.turmasLoading && !fastSecretariaCurso.turmas.length">
                            Nenhuma turma disponível
                          </li>
                        </ul>
                      </div>
                      <div
                        class="fd-app-mode-header-overlay-content-options-content-price-info-cupom"
                      />
                    </div>
                  </div>
                  <div class="fd-app-mode-header-overlay-content-options-social d-none">
                    <span>
                      <span class="mr-2">Compartilhe:</span>
                      <div
                        id="share"
                        class="jssocials"
                      >
                        <div class="jssocials-shares">
                          <div class="jssocials-share jssocials-share-facebook">
                            <a
                              target="_blank"
                              href="https://facebook.com/sharer/sharer.php?u=https%3A%2F%2Ffastead.com%2Fcursos_ondemand_detalhes.html%3FIdCurso%3D1215"
                              class="jssocials-share-link"
                            >
                              <i class="fab fa-facebook-square jssocials-share-logo" />
                            </a>
                            <div class="jssocials-share-count-box jssocials-share-no-count">
                              <span class="jssocials-share-count" />
                            </div>
                          </div>
                          <div class="jssocials-share jssocials-share-twitter">
                            <a
                              target="_blank"
                              href="https://twitter.com/share?url=https%3A%2F%2Ffastead.com%2Fcursos_ondemand_detalhes.html%3FIdCurso%3D1215&amp;text=FastEAD%20%7C%20Plataforma"
                              class="jssocials-share-link"
                            >
                              <i class="fab fa-twitter-square jssocials-share-logo" />
                            </a>
                            <div class="jssocials-share-count-box jssocials-share-no-count">
                              <span class="jssocials-share-count" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </header>
    <div class="fd-app-wrap" style="min-height: 500px">
      <div class="container">
        <div class="fd-curso-detalhes">
          <div v-if="getUrlParameter('secretaria')" class="fd-curso-detalhes-body">
            <div class="row mt-4">
              <div class="col-12">
                <h3>O que você vai aprender</h3>
              </div>
              <div class="col-12 mt-4">
                <div class="card border-0 mb-4" v-for="modulo in fastSecretariaCurso.modulos" :key="modulo.id_secretaria_curso_programa">
                  <div class="card-body p-0">
                    <h5 class="card-title text-info">{{modulo.sigla_programa}}</h5>
                    <p v-if="modulo.creditos" class="card-text m-0 d-none">
                      Créditos: {{modulo.creditos}}
                    </p>
                    <p v-if="modulo.preco && fastSecretariaCurso.venda_modulo" class="card-text m-0 d-none">
                      Valor do módulo: R$ {{formataPreco(modulo.preco)}}
                    </p>
                    
                    <div class="list-group mt-2">
                      <a href="#" class="list-group-item list-group-item-action flex-column align-items-start" v-for="(uc, index) in modulo.ucs" :key="index">
                        <div class="d-flex w-100 justify-content-between">
                          <h6 class="mb-1">{{uc.nome_unidade_curricular}}</h6>
                          <small v-if="uc.carga_horaria">{{uc.carga_horaria}}</small>
                        </div>
                        <small>{{uc.detalhe_unidade_curricular}}</small>
                      </a>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
            

          </div>
          <div v-else class="fd-curso-detalhes-body">
            <div class="fd-curso-detalhes-ementa">
              <h2>O que você vai aprender</h2>
              <ul
                v-if="fastCurso.length"
                class="fd-curso-detalhes-ementa-list"
              >
                <li
                  v-for="aula in fastCurso"
                  :key="aula.id_aula"
                  class="p-1"
                >
                  <div>
                    <i class="fas fa-check mr-2" />
                    {{ aula.titulo_aula ? aula.titulo_aula : "Sem detalhes" }}
                  </div>
                </li>
              </ul>
              <ul
                v-else
                class="fd-curso-detalhes-ementa-list"
              >
                Curso solicitado sob demanda
              </ul>
            </div>

            <div class="fd-curso-detalhes-descricao" />
            <div class="fd-curso-detalhes-docentes">
              <h2 v-if="fastProfessores.length">
                Professores
              </h2>

              <ul
                v-if="fastProfessores.length"
                class="fd-curso-detalhes-docentes-list"
              >
                <li
                  v-for="prof in fastProfessores"
                  :key="prof.id_aula"
                  class="fd-curso-detalhes-docentes-list-item"
                >
                  <div
                    v-if="prof.first_name"
                    class="fd-curso-detalhes-docentes-list-item-avatar"
                    :style="{ backgroundImage: 'url(' + ajustaLinkImageUser(prof.image) + ')' }"
                  />
                  <div class="fd-curso-detalhes-docentes-list-item-info">
                    <strong>{{ prof.first_name }} {{ prof.last_name }}</strong>
                    <p v-if="prof.licenciatura == '1'">
                      Graduado
                    </p>
                    <p v-else-if="prof.licenciatura == '2'">
                      Especialista
                    </p>
                    <p v-else-if="prof.licenciatura == '3'">
                      Mestre
                    </p>
                    <p v-else-if="prof.licenciatura == '4'">
                      Doutor
                    </p>
                  </div>
                </li>
              </ul>

              <ul
                v-else
                class="fd-curso-detalhes-docentes-list"
              >
                Nenhum professor cadastrado
              </ul>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  </div>
  
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";
import headerCursoInfo from "@/assets/images/header-curso-info.jpg";

export default {
  name: "LojaVirtualCurso",
  mixins: [methods],
  data: function () {
    return {
      headerCursoInfo,
      //fastPlataforma
      fastPlataforma: {
        banner_plataforma: "",
        id_plataforma: 0,
        logo_plataforma: "",
        nome_plataforma: "",
        url_plataforma: "",
      },
      // fastCurso
      fastCurso: [],
      fastCursoAtividades: {},
      fastProfessores: [],
      // Secretaria
      fastSecretariaCurso: {
        carga_horaria: 0,
        creditos_total: 0,
        detalhe_curso: "",
        gera_certificado: null,
        id_base_curricular: null,
        id_plataforma: 0,
        id_secretaria_curso: 0,
        logo_curso: "",
        nivel: "",
        nome_curso: "",
        possui_aproveitamento: false,
        preco: 0,
        publicado: false,
        total_programas: false,
        venda_modulo: false,
        venda_unidade_curricular: false,
        loading: true,
        modulosLoading: true,
        ucsLoading: true,
        modulos: [],
        turmasLoading: true,
        turmas: []
      }
    };
  },
  mounted() {
    // Captura ID da plataforma pela URL
    if (this.$route.params.id_plataforma && this.$route.params.id_curso) {
      this.$store.state.fastPermissoes.id_plataforma = this.$route.params.id_plataforma;
      
      this.getPlataformaData();
      if (this.getUrlParameter('secretaria')) {
        this.getSecretariaCursoDetalhes(parseInt(this.$route.params.id_plataforma), parseInt(this.$route.params.id_curso))
      } else {
        this.getFastCurso();
        this.getFastCursoAtividades();
      }
      
    }
  },
  methods: {
    async getPlataformaData() {
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_plataforma/lista_detalhes_cliente?id_plataforma=" +
            this.$route.params.id_plataforma,
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length) {
          this.fastPlataforma = obj[0];
        }
      } catch (e) {
        console.log("Erro", e);
      }
    },
    async getSecretariaCursoDetalhes(id_plataforma, id_secretaria_curso){
      let fastSecretariaCurso = {}
      let erros = []
      this.promiseGetFastApi("api/fast_secretaria_curso/lista_loja_curso_detalhes", "id_plataforma=" + id_plataforma + "&id_secretaria_curso=" + id_secretaria_curso).then(res => {
        if (res.length) {
          return res[0]
        }
        else { 
          erros.push("Erro ao buscar informações do curso")          
        }
      })
      .then(res => {
        if (!erros.length) {
          res.loading = false
          res.modulosLoading = false
          res.ucsLoading = false
          res.modulos = []
          res.turmasLoading = false
          res.turmas = []
          fastSecretariaCurso = res  
   
          return this.promiseGetFastApi("api/fast_secretaria_curso/lista_loja_curso_modulos", "id_plataforma=" + id_plataforma + "&id_secretaria_curso=" + id_secretaria_curso)
        } else {
          erros.forEach(e => this.exibeToasty(e, "error"))
        }
      })
      .then(res => {
        this.fastSecretariaCurso.modulosLoading = false
        if (res.length) {
          res.sort(this.reornedarArrayObjetos("sequencia"))
          fastSecretariaCurso.modulos = res
          return this.promiseGetFastApi("api/fast_secretaria_curso/lista_loja_curso_ucs", "id_plataforma=" + id_plataforma + "&id_secretaria_curso=" + id_secretaria_curso)
        } else {
          return false
        }
      })
      .then(res => {        
        if (res.length) {
          res.sort(this.reornedarArrayObjetos("sequencia"))
          fastSecretariaCurso.modulos.forEach(modulo => {    
            modulo.ucs = []
            res.forEach(uc => {
              if (uc.id_secretaria_curso_programa == modulo.id_secretaria_curso_programa) modulo.ucs.push(uc)              
            })   
          })
        }
        this.fastSecretariaCurso.ucsLoading = false
        return this.promiseGetFastApi("api/fast_secretaria_curso/lista_loja_curso_turmas", "id_plataforma=" + id_plataforma + "&id_secretaria_curso=" + id_secretaria_curso)
      })
      .then((res) => {     
        if (res.length) fastSecretariaCurso.turmas = res
        
        this.fastSecretariaCurso.turmasLoading = false
      })
      .then(() => {        
        this.fastSecretariaCurso = fastSecretariaCurso 
        console.log("FINALIZOU - this.fastSecretariaCurso", this.fastSecretariaCurso)
        this.$store.state.fastCarregando = false
      })
      .catch(e => {
        this.exibeToasty(e, "error")
        this.fastSecretariaCurso.loading = false
        this.$store.state.fastCarregando = false
      })
    },
    async getFastCurso() {
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_curso/lista_detalhes_ondemand?id_plataforma=" +
            this.$route.params.id_plataforma +
            "&id_curso=" +
            this.$route.params.id_curso,
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length) {
          obj[0].preco = "";
          let incluidoCarrinho = false;
          if (this.$store.state.fastCarrinho.length) {
            this.$store.state.fastCarrinho.forEach((p) => {
              if (obj[0].id_curso == p.id_curso) incluidoCarrinho = true;
            });
          }
          obj[0].carrinho = incluidoCarrinho;

          const professores = Array.from(
            new Set(obj.map((a) => a.id_professor))
          ).map((id_professor) => {
            return obj.find((a) => a.id_professor === id_professor);
          });
          this.fastProfessores = professores;
          this.fastCurso = obj;
        } else {
          this.fastCurso = [];
        }
        console.log("getFastCurso", obj);

        // Informa ao component pai para interromper o loading
        this.$store.state.fastCarregando = false;
      } catch (e) {
        console.log("Erro", e);
      }
    },
    async getFastCursoAtividades() {
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_curso/lista_atividades_ondemand?id_curso=" +
            this.$route.params.id_curso,
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length) {
          this.fastCursoAtividades = obj[0];
        } else {
          this.fastCursoAtividades = [];
        }
        console.log("getFastCursoAtividades", obj);
      } catch (e) {
        console.log("Erro", e);
      }
    },
    async getCursoPrecoVindi(curso) {
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_curso/lista_produto_vindi?id_produto_vindi=" +
            curso.id_produto_vindi,
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        if (JSON.parse(json).product)
          this.fastCurso[0].preco = JSON.parse(
            json
          ).product.pricing_schema.short_format;
      } catch (e) {
        console.log("Erro", e);
      }
      console.log(curso);
    },
    // Carrinho
    adicionaCarrinho(curso) {
      // Limpa carrinho para não misturar curso livre com curso da secretaria
      if (this.getUrlParameter('secretaria')) {
          this.$store.state.fastCarrinho = []
      } else {
        if (this.$store.state.fastCarrinho.length) {
          let incluido = false;
          
          this.$store.state.fastCarrinho.forEach((p) => {
            if (curso.id_curso == p.id_curso) incluido = true;
          });       

          if (!incluido) {
            this.$store.state.fastCarrinho.push(curso);
            curso.carrinho = true;
          }
        } else {
          this.$store.state.fastCarrinho.push(curso);
          curso.carrinho = true;
        }
      }
      this.setFastSessao(
        settings.fastSessaoCarrinho,
        this.$store.state.fastCarrinho
      );

      // Curso da secretaria vai direto pro checkout
      if (this.getUrlParameter('secretaria')) {
        window.location.href =
        "/loja-virtual/" + this.$route.params.id_plataforma + "/checkout?id_secretaria_curso=" + curso.id_secretaria_curso;
      } else {
        window.location.href =
        "/loja-virtual/" + this.$route.params.id_plataforma + "/carrinho";
      }
      
    },
  },
};
</script>

<style scope>
.fast-fundo-blur {
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-clip: padding-box;
}
</style>
